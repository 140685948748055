<template>
  <div>
    <div class="right-top-btn-group">
      <a-button @click="back">返回</a-button>
      <a-button type="primary" @click="createChapter">添加目录</a-button>
    </div>

    <Draggable v-model="list"
               animation="300"
               @change="onChapterReorder">
      <a-card v-for="chapter in list"
              :key="chapter.id"
              class="mb-20">
        <div slot="title">{{ chapter.title }}</div>
        <div slot="extra">
          <a-button class="mr-30" @click="editChapter(chapter)">编辑</a-button>
          <a-button class="mr-30" @click="onDeleteChapter(chapter)">删除</a-button>
          <a-button class="" @click="onAddCourse(chapter)">添加课程</a-button>
        </div>

        <CourseList :data="chapter.course || []"
                    :dos-id="id"
                    :chapter-id="chapter.id"
                    :fetching="loading"
                    @deleted="onDeleteCourse($event, chapter)"/>
      </a-card>
    </Draggable>


    <template v-if="!loading && (!list || !list.length)">
      <a-empty/>
    </template>

    <CreateChapterModal ref="createChapterModalRef" @closed="onChapterClosed"/>
    <SelectCourseModal ref="selectCourseModalRef" @closed="onSelectCourseClosed"/>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import {
  getDosDetailList,
  deleteChapter,
  reorderChapter,
} from '../api';

import CreateChapterModal from '../components/CreateChapterModal.vue';
import CourseList from '../components/CourseList.vue';
import SelectCourseModal from '../components/SelectCourseModal.vue';

export default {
  name: 'Chapter',
  components: {
    Draggable,
    CreateChapterModal,
    CourseList,
    SelectCourseModal,
  },
  data() {
    return {
      loading: false,

      id: '',
      list: [],
    };
  },
  created() {
    this.id = this.$route.query?.dosId || '';
    this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },

    onSearch() {
      this.getData();
    },
    async getData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getDosDetailList({
        code_id: this.id,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return;
      }
      this.list = data?.data || [];
    },

    createChapter() {
      this.onShowChapterModal();
    },
    editChapter(evt) {
      this.onShowChapterModal(evt);
    },
    onShowChapterModal(evt) {
      if (this.$refs.createChapterModalRef?.show) {
        this.$refs.createChapterModalRef.show({
          ...(evt || {}),
          code_id: this.id,
        });
      }
    },
    onChapterClosed(evt) {
      if (evt) {
        this.onSearch();
      }
    },

    async onChapterReorder(evt) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        code_id: this.id,
        chapter_ids: this.list.map((i) => i.id).filter((i) => !!i).join(','),
      };
      const data = await reorderChapter(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '排序保存失败');
        this.rollbackReorder(evt);
        return;
      }
      this.$message.success(data.message || '排序保存成功');
    },
    rollbackReorder(evt) {
      const oldIndex = evt?.moved?.oldIndex;
      const newIndex = evt?.moved?.newIndex;
      const [movedItem] = this.data.splice(newIndex, 1);
      this.list.splice(oldIndex, 0, movedItem);
    },

    onDeleteChapter(record) {
      if (this.loading) {
        return;
      }
      this.$confirm({
        content: `确认删除该章节？`,
        onOk: () => this.onConfirmDeleteChapter(record),
      });
    },
    async onConfirmDeleteChapter(record) {
      const data = await deleteChapter({ chapter_id: record.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data.message || '删除成功');
      this.onSearch();
    },

    onDeleteCourse(evt, chapter) {
      chapter.course = (chapter.course || []).filter((i) => i.id && i.id !== evt.dos_course_id);
    },

    onAddCourse(record) {
      if (this.$refs.selectCourseModalRef?.show) {
        this.$refs.selectCourseModalRef.show({
          code_id: this.id,
          chapter_id: record.id,
        });
      }
    },
    onSelectCourseClosed(evt) {
      if (evt?.modified) {
        this.onSearch();
      }
    },

  },
};
</script>

<style scoped lang="scss">
.right-top-btn-group {
  position: relative;
  top: -40px;
  float: right;
  height: 0;
}
</style>
