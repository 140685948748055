<template>
  <a-modal :visible="shown"
           :title="title"
           :confirm-loading="loading"
           :mask-closable="true"
           :centered="true"
           :footer="null"
           :after-close="afterClosed"
           @cancel="close()">
    <a-form-model ref="formRef"
                  :model="form"
                  :rules="rules"
                  :label-col="{span: 6}"
                  :wrapper-col="{span: 18}"
                  label-align="right"
                  class="form-model flex-grow"
                  @submit="onSubmit">
      <a-form-model-item label="课程名称" prop="dos_title" class="form-item">
        <a-input ref="titleRef" v-model="form.dos_title" placeholder="请填写课程名称"/>
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{span: 24}" style="text-align: center;">
        <a-button type="primary" :loading="loading" @click="onSubmit">保存</a-button>
      </a-form-model-item>
    </a-form-model>

  </a-modal>
</template>

<script>
import { editCourseTitle } from '../api';

export default {
  name: 'EditCourseTitleModal',
  data() {
    return {
      shown: false,
      loading: false,

      id: '',
      title: '',

      form: {
        dos_course_id: '',
        dos_title: '',
      },

      rules: {
        title: [{ required: true, trigger: 'blur', message: '请填写课程名称' }],
      },
    };
  },
  methods: {
    show({ id, code_course_title } = {}) {
      this.id = id;
      this.title = id ? `修改课程名称` : `添加课程`;

      this.form.dos_course_id = id || '';
      this.form.dos_title = code_course_title || '';

      this.shown = true;

      this.$nextTick(() => {
        this.$refs.titleRef?.focus();
      });
    },
    close(evt) {
      this.$emit('closed', evt);
      this.shown = false;
    },
    afterClosed() {
      this.id = '';
      this.title = '';
      this.form = {
        dos_course_id: '',
        dos_title: '',
      };
    },

    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((validated) => {
          if (validated) {
            resolve(true);
          } else {
            reject(false);
          }
        });
      });
    },
    onSubmit() {
      this.validate().then(() => {
        this.onSave();
      });
    },
    async onSave() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        ...this.form,
      };

      const data = await editCourseTitle(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');

      this.close({ all: !this.id, item: params });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
