import request from '@/utils/Request';

// 用于搜索的课程列表
export function getCourseListForSelection(params) {
  return request({
    url: `/dos/admin/courseList`,
    method: 'get',
    params,
  });
}

// 二维码列表
export function getCodeList(params) {
  return request({
    url: `/dos/admin/codeList`,
    method: 'get',
    params,
  });
}

// dos信息修改页
export function getCodeInfo(params) {
  return request({
    url: `/dos/admin/getDosInfo`,
    method: 'get',
    params,
  });
}

// 添加/修改二维码信息
export function saveCodeInfo(data) {
  return request({
    url: `/dos/admin/upDosCode`,
    method: 'post',
    data,
  });
}

// 二维码课程列表
export function getDosDetailList(params) {
  return request({
    url: `/dos/admin/dosCourseList`,
    method: 'get',
    params,
  });
}

// 添加/修改章节
export function saveChapter(data) {
  return request({
    url: `/dos/admin/addDirectory`,
    method: 'post',
    data,
  });
}

// 删除章节
export function deleteChapter(data) {
  return request({
    url: `/dos/admin/delChapter`,
    method: 'post',
    data,
  });
}

// 添加课程
export function addCourse(data) {
  return request({
    url: `/dos/admin/addCourseIds`,
    method: 'post',
    data,
  });
}

// 修改课程在dos扫码中的名称
export function editCourseTitle(data) {
  return request({
    url: `/dos/admin/updDosCourseTitle`,
    method: 'post',
    data,
  });
}

// 删除课程
export function deleteCourse(data) {
  return request({
    url: `/dos/admin/delCourseInDos`,
    method: 'post',
    data,
  });
}

// 章节拖动排序
export function reorderChapter(data) {
  return request({
    url: `/dos/admin/dragSortingByChapter`,
    method: 'post',
    data,
  });
}

// 课程拖动排序
export function reorderCourse(data) {
  return request({
    url: `/dos/admin/dragSortingByCourse`,
    method: 'post',
    data,
  });
}

// 未加入dos的课程列表
export function getFreeCourseList(params) {
  return request({
    url: `/dos/admin/noJoinCourseList`,
    method: 'get',
    params,
  });
}

// 学习日志
export function getDosLog(params) {
  return request({
    url: `/dos/admin/getUserCourseLogList`,
    method: 'get',
    params,
  });
}
